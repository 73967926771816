
h1 {
  font-size: xx-large;
}
h2 {
  font-size: x-large;
}
h3 {
  font-size: large;
}
.auth-button {
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 18px;
}
.auth-button:hover {
    background-color: #fff;
    color: blue;
    opacity: 0.9;
}
.auth-button_link {
    color: #fff;
}

.auth-button_link:hover {
    color: #fff;
    font-size: 16px;
}

.auth-title-text {
    text-align: left;
    color: #fff;
    margin-top: 20px;
}

.center-text {
  text-align: center;
  color: #fff;
  margin-top: 20px;
}

.wiki-style-text {
  text-align: left;
  color: #fff;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}


.wiki-style-text-mini {
  text-align: left;
  color: #fff;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}


.wiki-style-text-large {
  text-align: left;
  color: #fff;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.wide-img {
    width: 50%;
}

.main-wrapper {
    align-items: center;
    min-height: 400px;
}

.img-home-wrapper {
    display: flex;
    justify-content: center;
}

.img-home {
    width: 90%;
}

@media(min-width: 768px) {
    .img-home {
        width: 70%;
    }
}

.half-size-full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

@media(min-width: 768px) {
  .half-size-full-screen {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 50%;
  }
}


/* below is what I copied from CHAT GPT to get an image on the left/right of text: */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .image-container,
  .text-container {
    width: 50%;
    text-align: center; /* Center align the content */
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
  
  .text-container {
    width: 50%;
    padding: 0 20px;
  }
  
  .text-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
    justify-content: center;
  }
  
  .text-container p {
    font-size: 16px;
    justify-content: center;
  }

  .light-blue {
  color: #65aaff;
}
 
.image-responsive {
  width: 100%;
  margin: 0 auto 10px;
  display: block;
  cursor: pointer;
}

.image-responsive-mobile {
  width: 30%;
  margin: 0 auto 10px;
  display: block;
  cursor: pointer;
}

@media (max-width: 768px) {
    .container {
      flex-direction: column; /* Change to column layout */
      height: auto; /* Remove fixed height */
      min-height: min-content; /* Adjusts height based on content */
    }

    .image-container,
    .text-container {
      width: 100%; /* Make both containers full width */
    }
  
    .image-container {
      width: 90%; /* Make both containers full width */
      order: 1; /* Move image container below text */
    }


    .image-container-reverse {
        width: 90%;
        order: -1; /* Move image container below text */
      }
  
  }

